import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
// *helmet
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ImageDataContext } from "components/ImageDataContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { dataStore } from "store/dataStore";
import { settingsStore } from "store/settingsStore";

function Home() {
  const { t, i18n } = useTranslation("index");
  const { setImagesData } = useContext(ImageDataContext);
  const navigate = useNavigate();
  const [resetFromData] = dataStore((state) => [state.resetFromData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    resetFromData();
    setImagesData([]);
  }, [resetFromData, setImagesData]);

  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);

  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
    navigate(direction === "ltr" ? "/ar" : "/en");
  };

  const lang = localStorage.getItem("i18nextLng");
  return (
    <>
      {" "}
      <div className="nav">
        <button onClick={toggleLang}>
          <div className="langBtn">
            {direction === "ltr" ? "عربي" : "English"}
          </div>
        </button>
      </div>
      <div style={{ marginTop: "10vh" }}>
        <Typography sx={{ fontSize: "1.5rem" }}>
          {t("Please select the nationality")}
        </Typography>
        {/* <Typography sx={{ pt: '2' }} varient="body1">{t('Lorem ipsum dolor sit amet co')}</Typography> */}
        <Box sx={{ mt: "10px" }}>
          <Link to={`/syrian/1`} style={{ textDecoration: "none" }}>
            <div style={{ marginTop: "2vh" }}>
              <Box
                sx={{
                  display: "flex",
                  mt: "5vh",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { md: "40%", sm: "100%" },
                  borderRadius: "12px",
                  border: "1px solid #DCDBDD",
                  p: "20px",
                  mt: "30px",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "text.main",
                      borderBottom: "none",
                    }}
                  >
                    {t("Syrian Arab")}
                  </Typography>
                </div>
                <div
                  style={{
                    transform:
                      lang === "ar" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <ArrowBackIcon sx={{ color: "text.main" }} />
                </div>
              </Box>
            </div>
          </Link>
          <Link to="/syrian/2" style={{ textDecoration: "none" }}>
            <div style={{ marginTop: "2vh" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "5vh",
                  alignItems: "center",
                  width: { md: "40%", sm: "100%" },
                  borderRadius: "12px",
                  border: "1px solid #DCDBDD",
                  p: "20px",
                  marginTop: "30px",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "text.main",
                      borderBottom: "none",
                    }}
                  >
                    {t("Palestinian Syrian")}
                  </Typography>
                </div>
                <div
                  style={{
                    transform:
                      lang === "ar" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <ArrowBackIcon sx={{ color: "text.main" }} />
                </div>
              </Box>
            </div>
          </Link>
          <Link to="/foreigner" style={{ textDecoration: "none" }}>
            <div style={{ marginTop: "2vh" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { md: "40%", sm: "100%" },
                  borderRadius: "12px",
                  border: "1px solid #DCDBDD",
                  p: "20px",
                  mt: "30px",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "text.main",
                      borderBottom: "none",
                    }}
                  >
                    {t("foreigner")}
                  </Typography>
                </div>
                <div
                  style={{
                    transform:
                      lang === "ar" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  <ArrowBackIcon sx={{ color: "text.main" }} />
                </div>
              </Box>
            </div>
          </Link>
        </Box>
      </div>
    </>
  );
}

export default Home;
