import React from "react";
import SideBar from "components/sideBard/SideBar";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "background.main",
        }}
      >
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Box sx={{ marginTop: "100px" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </div >
  );
};

export default Layout;
