//* Mui
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
//* Logo
import logo from "../../asset/images/logo_light.png";
//* Custom Hooks
import { useConfirmationSubmit } from "./hooks/useConfirmationSubmit";
//* Third Party Components
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { dataStore } from "store/dataStore";

const Confirmation = () => {
  //* use Confirmation Submit
  const {
    handleCreate,
    t,
    done,
    loading,
    FormData,
    lang,
    backImage,
    FrontImage,
    reportTemplateRef,
    returnData,
    sub_category,
    categoryValue,
    subcategory_id,
    options,
    findNameById,
    handlePrintWithImages,
    withImages,
    handlePrintWithoutImages,
    navigate,
  } = useConfirmationSubmit();

  const b = dataStore((state) => state.branch);
  const a = dataStore((state) => state.accountType);

  return (
    <>
      <div className="nav home">
        {!loading && (
          <Link to={"/"}>
            <button>
              <Home />
            </button>
          </Link>
        )}
      </div>
      <Box
        ref={reportTemplateRef}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            p: { sm: "5px", md: "24px" },
            pt: "2px",
            width: { sm: "100%", md: "70%" },
            zIndex: "6",
            borderRadius: "25px",
          }}
        >
          {/* //* details */}
          <div id="form" style={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={logo} alt=" " id="LogoImg" />
              <p
                style={{ borderBottom: "1px solid #E1E2EB", width: "100%" }}
              ></p>
              <h1>{t("CONFIRMATION PAGE")}&nbsp;</h1>
            </Box>
            <Box
              className="html2pdf__page-break"
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                my: "2rem",
              }}
            >
              <Typography
                className="text"
                style={{
                  background: "#F9B700",
                  width: "100%",
                  borderRadius: "4px",
                  padding: "8px 20px",
                }}
              >
                {t("Personal information")}&nbsp;
              </Typography>
              <Grid
                container
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  my: "2rem",
                }}
              >
                <Grid
                  item
                  md="3"
                  xs="12"
                  sx={{ border: "1px solid #E1E2EB" }}
                  className="printBox"
                >
                  <span className="text" style={{ width: "max-content" }}>
                    {" "}
                    {t("first_name")}&nbsp;
                  </span>{" "}
                  <br />
                  <span
                    className="text"
                    lang="en"
                    dir="ltr"
                    style={{
                      textAlign: lang === "ar" ? "right" : "left",
                      width: "max-content",
                    }}
                  >
                    {FormData?.first_name}
                  </span>
                </Grid>
                <Grid
                  item
                  md="3"
                  xs="12"
                  sx={{ border: "1px solid #E1E2EB" }}
                  className="printBox"
                >
                  <span className="text" style={{ width: "max-content" }}>
                    {" "}
                    {t("last_name")}&nbsp;
                  </span>{" "}
                  <br />
                  <span
                    className="text"
                    lang="en"
                    dir="ltr"
                    style={{
                      textAlign: lang === "ar" ? "right" : "left",
                      width: "max-content",
                    }}
                  >
                    {FormData?.last_name}
                  </span>
                </Grid>

                {FormData?.first_name_en ? (
                  <Grid
                    item
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("first_name_en")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {FormData?.first_name_en}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}

                {FormData?.last_name_en ? (
                  <Grid
                    item
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("last_name_en")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {FormData?.last_name_en}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}

                {FormData?.father_name ? (
                  <Grid
                    item
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("father_name")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {FormData?.father_name}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}

                {FormData?.father_name_en ? (
                  <Grid
                    item
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("father_name_en")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {FormData?.father_name_en}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}
                {FormData?.type_id ? (
                  <Grid
                    item
                    data-html2canvas-ignore
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("account-type")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {a.title}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}
                {FormData?.branch_id ? (
                  <Grid
                    item
                    md="3"
                    xs="12"
                    sx={{ border: "1px solid #E1E2EB" }}
                    className="printBox"
                  >
                    <span className="text" style={{ width: "max-content" }}>
                      {" "}
                      {t("Branch")}&nbsp;
                    </span>{" "}
                    <br />
                    <span
                      className="text"
                      lang="en"
                      dir="ltr"
                      style={{
                        textAlign: lang === "ar" ? "right" : "left",
                        width: "max-content",
                      }}
                    >
                      {b}
                    </span>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid
                  item
                  md="3"
                  xs="12"
                  sx={{ border: "1px solid #E1E2EB" }}
                  className="printBox"
                >
                  <span className="text" style={{ width: "max-content" }}>
                    {" "}
                    {t("birthdate")}&nbsp;
                  </span>{" "}
                  <br />
                  <span
                    className="text"
                    lang="en"
                    dir="ltr"
                    style={{
                      textAlign: lang === "ar" ? "right" : "left",
                      width: "max-content",
                    }}
                  >
                    {FormData?.birthdate}
                  </span>
                </Grid>
                {returnData()}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                my: "2rem",
              }}
            >
              <Typography
                className="text"
                style={{
                  background: "#F9B700",
                  width: "100%",
                  borderRadius: "4px",
                  padding: "8px 20px",
                  marginBottom: "1rem",
                }}
              >
                {t("Contact information")}&nbsp;
              </Typography>
              <Box
                sx={{
                  border: "1px solid #E1E2EB",
                  width: "100%",
                  padding: "15px 20px",
                  my: "0.5rem",
                  borderRadius: "5px",
                  mx: "5px",
                }}
              >
                <strong className="phoneNum">{t("phone_number")} </strong>{" "}
                {": "}
                <span className="text">{String(FormData?.phone_number)}</span>
              </Box>
              <Box
                sx={{
                  border: "1px solid #E1E2EB",
                  width: "100%",
                  padding: "15px 20px",
                  my: "0.5rem",
                  borderRadius: "5px",
                  mx: "5px",
                }}
              >
                <strong>{t("telephone_number")} </strong> {": "}
                <span className="text">
                  {String(FormData?.telephone_number)}
                </span>
              </Box>
              <Box
                sx={{
                  border: "1px solid #E1E2EB",
                  width: "100%",
                  padding: "15px 20px",
                  my: "0.5rem",
                  borderRadius: "5px",
                  mx: "5px",
                }}
              >
                <strong>{t("address")} </strong> {": "}
                <span
                  className="text"
                  lang="en"
                  dir="ltr"
                  style={{
                    textAlign: lang === "ar" ? "right" : "left",
                    width: "max-content",
                  }}
                >
                  {String(FormData?.address)}
                </span>
              </Box>
            </Box>

            <Box
              className={withImages && `html2pdf__page-break`}
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                my: "2rem",
              }}
            >
              <Typography
                className="text"
                style={{
                  background: "#F9B700",
                  width: "100%",
                  borderRadius: "4px",
                  padding: "8px 20px",
                  marginBottom: "1rem",
                }}
              >
                {t("Work information")}&nbsp;
              </Typography>
              <Box
                sx={{
                  border: "1px solid #E1E2EB",
                  width: "100%",
                  padding: "15px 20px",
                  my: "0.5rem",
                  borderRadius: "5px",
                  mx: "5px",
                }}
              >
                <strong>{t("category_id")} </strong>
                {": "}
                <span className="text">
                  {" "}
                  {findNameById(options, categoryValue)}&nbsp;
                </span>
              </Box>
              <Box
                sx={{
                  border: "1px solid #E1E2EB",
                  width: "100%",
                  padding: "15px 20px",
                  my: "0.5rem",
                  borderRadius: "5px",
                  mx: "5px",
                }}
              >
                <strong>{t("subcategory_id")} </strong>
                {": "}
                <span className="text">
                  {findNameById(subcategory_id, sub_category)}&nbsp;
                </span>
              </Box>
              {FormData?.work_description && (
                <Box
                  sx={{
                    border: "1px solid #E1E2EB",
                    width: "100%",
                    padding: "15px 20px",
                    my: "0.5rem",
                    borderRadius: "5px",
                    mx: "5px",
                  }}
                >
                  <strong>{t("Work description")} </strong>
                  {": "}
                  <span
                    className="text"
                    lang="en"
                    dir="ltr"
                    style={{
                      textAlign: lang === "ar" ? "right" : "left",
                      width: "max-content",
                    }}
                  >
                    {" "}
                    {FormData?.work_description}&nbsp;
                  </span>
                </Box>
              )}
              <Typography className="text">
                {t("the terms and conditions have been approved")}&nbsp;
              </Typography>
              <br />
            </Box>

            {withImages ? (
              <>
                <div className="idImageContainer">
                  <img
                    className="Id_image"
                    src={backImage?.BackImageLink}
                    alt="back id"
                  />
                  <div className="html2pdf__page-break"></div>
                  <img
                    className="Id_image"
                    src={FrontImage?.FrontImageLink}
                    alt="front id"
                  />
                </div>
              </>
            ) : (
              " "
            )}
          </div>
          <Box
            id="id-test"
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "15px",
            }}
          >
            {done ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  className="btnSubmit"
                  sx={{
                    mx: "25px",
                    width: "60%",
                    borderRadius: "16px",
                    background: "#F9B700",
                    color: "white",
                    "&.Mui-disabled": {
                      backgroundColor: "#bfb9a7",
                      color: "white",
                    },
                    "&:hover": {
                      backgroundColor: "#f9d160",
                    },
                  }}
                  type="submit"
                  onClick={() => handlePrintWithImages()}
                >
                  <Typography sx={{ color: "background.main" }}>
                    {t("Download file with images")}
                  </Typography>
                </Button>
                <Button
                  className="btnSubmit"
                  sx={{
                    mx: "25px",
                    width: "60%",
                    borderRadius: "16px",
                    background: "#081937",
                    mt: "1rem",
                    color: "white",
                    "&.Mui-disabled": {
                      backgroundColor: "#818ea3",
                      color: "white",
                    },
                    "&:hover": {
                      backgroundColor: "#31466b",
                      color: "white",
                    },
                  }}
                  type="submit"
                  onClick={() => handlePrintWithoutImages()}
                >
                  <Typography sx={{ color: "background.main" }}>
                    {t("Download file without images")}
                  </Typography>
                </Button>
              </Box>
            ) : (
              <>
                <Button
                  sx={{
                    width: "20%",
                    mx: "2rem",
                    borderRadius: "16px",
                    background: "#Fff",
                    border: "1px solid #F9B700",
                  }}
                  onClick={() => navigate(-1)}
                  disabled={loading}
                >
                  <Typography sx={{ color: "#081937" }}>{t("Back")}</Typography>
                </Button>
                <Button
                  className="btnSubmit"
                  sx={{
                    mx: "2em",
                    width: "80%",
                    borderRadius: "16px",
                    background: "#081937",
                    color: "white",
                    "&.Mui-disabled": {
                      backgroundColor: "#818ea3",
                      color: "white",
                    },
                    "&:hover": {
                      backgroundColor: "#31466b",
                      color: "white",
                    },
                  }}
                  onClick={() => handleCreate()}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={"20px"} />
                  ) : (
                    <Typography sx={{ color: "background.main" }}>
                      {t("Confirm")}
                    </Typography>
                  )}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Confirmation;
