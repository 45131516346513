import { useMemo, useEffect, useRef, useState, useContext } from "react";
//* Third party Component
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import html2pdf from "html2pdf.js";
//* Store
import { dataStore } from "store/dataStore";
//* Api
import { _syrian } from "api/syrian/syrian";
import { _foreigner } from "api/foreigner/foreigner";
//* Custom Hooks & Components
import { ImageDataContext } from "components/ImageDataContext";
import { useCategory, useSubCategory } from "hooks/Home/useCategory";

export const useConfirmationSubmit = () => {
  //* states
  const { t } = useTranslation("index");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [backImage, setbackImage] = useState();
  const [FrontImage, setFrontImage] = useState();
  const [subcategory_id, setsubcategory_id] = useState([]);
  const [options, setOptions] = useState([]);
  const [withImages, setWithImages] = useState(false);

  const [FormData] = dataStore((state) => [state.FormData]);

  //* hooks
  const { data } = useCategory();
  const { data: subdata } = useSubCategory();
  const { imagesData } = useContext(ImageDataContext);
  const [done, setDone] = useState(false);
  const params = useParams();
  const reportTemplateRef = useRef(null);

  let sub_category = FormData ? Number(FormData["subcategory_id"]) : null;
  let categoryValue = FormData ? Number(FormData["category_id"]) : null;
  let backImageItem = imagesData.find((item) =>
    item.hasOwnProperty("BackImageLink")
  );
  let FrontImageItem = imagesData.find((item) =>
    item.hasOwnProperty("FrontImageLink")
  );

  //* lang
  const lang = localStorage.getItem("i18nextLng");

  const handleCreate = async () => {
    setLoading(true);
    const formData = new window.FormData();
    const data = { ...FormData };
    delete data.front_id_face;
    delete data.back_id_face;

    for (const [key, value] of Object.entries(data)) {
      formData.append(key.toLowerCase(), value);
    }

    let backImageItem = imagesData.find((item) =>
      item.hasOwnProperty("BackImage")
    );
    if (backImageItem) formData.append("back_id_face", backImageItem.BackImage);

    imagesData.find(
      (item) => formData.append("front_id_face", item.FrontImage) !== null
    );

    if (params.type === "foreigner") {
      _foreigner
        .post(formData, setLoading, navigate)
        .then((res) => {
          setLoading(true);
          if (res.message === "success!") {
            setDone(true);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    } else {
      _syrian
        .post(formData, navigate)
        .then((res) => {
          setLoading(true);
          if (res.message === "success!") {
            setDone(true);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  };

  //* show data
  const returnData = () => {
    if (FormData) {
      const excludedKeys = [
        "front_id_face",
        "back_id_face",
        "code",
        "phone_number",
        "telephone_number",
        "category_id",
        "subcategory_id",
        "address",
        "work_description",
        "first_name",
        "last_name",
        "first_name_en",
        "last_name_en",
        "birthdate",
        "father_name_en",
        "father_name",
        "type_id",
        "branch_id"
      ];
      const sortedEntries = Object.entries(FormData).filter(
        ([key, value]) => !excludedKeys.includes(key)
      );

      return sortedEntries.map(([key, value]) => {
        if (value instanceof Date) value = value.toString();

        return (
          <Grid
            item
            md="3"
            xs="12"
            key={key}
            sx={{
              border: "1px solid #E1E2EB",
            }}
            className="printBox"
          >
            <span className="text" style={{ width: "max-content" }}>
              {params.type === "palestine"
                ? t(
                    key.charAt(0).toUpperCase() +
                      key.slice(1).replaceAll("_", " ")
                  )
                : null}
              {params.type === "syrian" ? t(key.replaceAll("_", " ")) : null}
              {params.type === "foreigner" ? t(key.replaceAll("_", " ")) : null}
              &nbsp;
            </span>
            <br />
            <span
              className="text"
              lang="en"
              dir="ltr"
              style={{
                textAlign: lang === "ar" ? "right" : "left",
                width: "max-content",
              }}
            >
              {String(value)}
            </span>
          </Grid>
        );
      });
    } else navigate("/");
  };

  function findNameById(dat, id) {
    for (let i = 0; i < dat.length; i++) {
      if (dat[i].id === id) {
        return dat[i].name;
      }
    }
    return null;
  }

  const style = `* {
        margin: "10px" !important;
    }
    body #LogoImg {
        width: "10px";
        height: "10px";
    }
    .printBox {
        padding: 10px !important;
    }
    @media (max-width: 1000px) {
        #form #LogoImg {
            width: 20%;
            height: 20%;
        }
        .printBox {
            font-size: 10px;
    
            max-width: 25% !important;
            display: grid !important;
            grid-template-columns: 2fr 2fr;     
        }
    }`;

  var opt = {
    margin: 0.5,
    image: { type: "png", quality: 0.99 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  const handlePrintWithoutImages = () => {
    setWithImages(false);
    setTimeout(() => {
      const formElement = document.getElementById("form");
      const divString = formElement.outerHTML;

      const modifiedContent = `<html>
    <head>
      <style>
      ${style}
      </style>
    </head>
    <body>
      ${divString}
      <br />
    </body>
    </html>`;

      html2pdf()
        .set(opt)
        .from(modifiedContent)
        .save("Bank of Jordan Syria.pdf");
    }, 1000);
  }; //* download pdf Without Images

  const handlePrintWithImages = () => {
    setWithImages(true);
    setTimeout(() => {
      const formElement = document.getElementById("form");
      const divString = formElement.outerHTML;
      const modifiedContent = `<html>
            <head>
              <style>
              ${style}
              </style>
            </head>
            <body>
              ${divString}
              <br />
            </body>
            </html>`;

      html2pdf()
        .set(opt)
        .from(modifiedContent)
        .save("Bank of Jordan Syria.pdf");
    }, 1000);
  }; //* download pdf With Images

  useEffect(() => {
    window.scrollTo(0, 0);
    if (FormData === undefined) {
      navigate("/");
    }
    // setDone(false)
  }, [FormData, navigate]);

  useMemo(() => {
    setbackImage(backImageItem);
    setFrontImage(FrontImageItem);
    if (data) setOptions(data?.categories);
    if (subdata) setsubcategory_id(subdata?.sub_category);
  }, [FrontImageItem, backImageItem, data, subdata]);

  return {
    loading,
    handleCreate,
    done,
    t,
    params,
    imagesData,
    FormData,
    navigate,
    lang,
    data,
    subdata,
    backImageItem,
    FrontImageItem,
    backImage,
    FrontImage,
    reportTemplateRef,
    returnData,
    style,
    sub_category,
    categoryValue,
    subcategory_id,
    options,
    findNameById,
    handlePrintWithImages,
    withImages,
    handlePrintWithoutImages,
  };
};
