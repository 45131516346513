//* MUI
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormHelperText } from "@material-ui/core";
//* Hooks
import { useForeignerCreate } from "./hooks/useForeignerCreate";
import { Controller } from "react-hook-form";

//* Styled Components
import {
  MenuItemStyled,
  SelectStyled,
  TextFieldStyled,
} from "components/styled/TextField";
//* Custom Components
import { countries } from "components/countries";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import SelectAccountType from "components/select-account-type";
import SelectBranch from "components/select-branch";

const Foreigner = () => {
  const {
    hanldeCreate,
    register,
    handleSubmit,
    t,
    errors,
    details,
    data,
    control,
    FormData,
    lang,
    navigate,
    watch,
    theme,
    trigger,
  } = useForeignerCreate();

  return (
    <>
      <div className="nav home">
        <Link to={"/"}>
          <button>
            <Home />
          </button>
        </Link>
      </div>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        className={`bg ${lang === "ar" ? "" : "en"}`}
      >
        <Box
          sx={{
            p: "25px",
            width: { sm: "100%", md: "80%" },
            background: "white",
            zIndex: "6",
            borderRadius: "25px",
          }}
        >
          <Box component="form">
            <Grid item container sx={{alignItems: "start"}} spacing={1}>
              <SelectAccountType
                watch={watch}
                register={register}
                errors={errors}
              />
              <SelectBranch register={register} errors={errors} watch={watch} />
              {/* //* //details */}
              {details.map((item, index) => (
                <Grid
                  item
                  key={index}
                  md={6}
                  xs={12}
                  sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
                >
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">
                      {t(
                        item.head.charAt(0).toUpperCase() + item.head.slice(1)
                      )}
                    </Typography>{" "}
                    {item.required === "required" ? (
                      <Typography variant="span" sx={{ color: "error.main" }}>
                        *
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Controller
                    control={control}
                    name={item.name}
                    {...register(item.register)}
                    render={({ field }) => (
                      <TextFieldStyled
                        variant="outlined"
                        style={{ width: "100%", borderRadius: "16px" }}
                        type={item.type}
                        placeholder={t(
                          item.placeholder.charAt(0).toUpperCase() +
                            item.placeholder.slice(1)
                        )}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger(item.name);
                        }}
                        disabled={item.name === "nationality" ? true : false}
                        error={errors[item.error]?.message ? true : false}
                        helperText={errors[item.helperText]?.message || ""}
                        defaultValue={item.defaultValue}
                        inputProps={{ pattern: item.pattern }}
                      />
                    )}
                  />
                </Grid>
              ))}

              {/*//* place_of_residence المحافظات*/}
              <Grid
                item
                md={6}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("Place_of_residence")}
                  </Typography>
                </Box>
                <SelectStyled
                  variant="outlined"
                  style={{ width: "100%" }}
                  sx={{
                    color: "text.main",
                    borderColor: "text.main",
                    width: "100%",
                  }}
                  {...register("Place_of_residence")}
                  error={errors.Place_of_residence?.message ? true : false}
                  defaultValue={
                    FormData ? FormData["Place_of_residence"] : null
                  }
                  helperText={errors.Place_of_residence?.message || ""}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {data
                    ? countries?.map((item, index) => {
                        return (
                          <MenuItemStyled
                            key={index}
                            value={lang === "ar" ? item.name_ar : item.name_en}
                          >
                            {lang === "ar" ? item.name_ar : item.name_en}
                          </MenuItemStyled>
                        );
                      })
                    : null}
                </SelectStyled>
                <FormHelperText error>
                  {errors.Place_of_residence?.message}
                </FormHelperText>
              </Grid>
              <Grid
                item
                md={"6"}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{t("address")}</Typography>{" "}
                    <Typography variant="span" sx={{ color: "error.main" }}>
                      *
                    </Typography>
                  </Box>
                </Box>
                <Controller
                  control={control}
                  name={"address"}
                  {...register("address")}
                  render={({ field }) => (
                    <TextFieldStyled
                      inputProps={{ maxLength: 60 }}
                      variant="outlined"
                      style={{ width: "100%", borderRadius: "16px" }}
                      type={"text"}
                      placeholder={t("address")}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger("address");
                      }}
                      error={errors.address?.message ? true : false}
                      defaultValue={FormData ? FormData["address"] : null}
                      helperText={errors.address?.message || ""}
                    />
                  )}
                />
              </Grid>
              {/* //*Nationality*/}
              <Grid
                item
                md={6}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("Nationality")}
                  </Typography>
                </Box>
                <SelectStyled
                  variant="outlined"
                  style={{ width: "100%" }}
                  sx={{
                    color: "text.main",
                    borderColor: "text.main",
                    width: "100%",
                  }}
                  {...register("Nationality")}
                  error={errors.Nationality?.message ? true : false}
                  helperText={errors.Nationality?.message || ""}
                  defaultValue={FormData ? FormData["Nationality"] : null}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {data?.countries?.map((item, index) => {
                    return (
                      <MenuItemStyled key={index} value={item.name}>
                        {item.name}
                      </MenuItemStyled>
                    );
                  })}
                </SelectStyled>
                <FormHelperText error>
                  {errors.Nationality?.message}
                </FormHelperText>
              </Grid>
              {/* //* gender */}
              <Grid
                item
                md={12}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">{t("gender")}</Typography>
                </Box>
                <SelectStyled
                  variant="outlined"
                  style={{ width: "100%" }}
                  sx={{
                    color: "text.main",
                    borderColor: "text.main",
                    width: "100%",
                  }}
                  {...register("gender")}
                  error={errors.gender?.message ? true : false}
                  helperText={errors.gender?.message || ""}
                  defaultValue={FormData ? FormData["gender"] : null}
                  MenuProps={{ disableScrollLock: true }}
                >
                  <MenuItemStyled value={lang === "ar" ? "انثى" : "female"}>
                    {t("femail")}
                  </MenuItemStyled>
                  <MenuItemStyled value={lang === "ar" ? "ذكر" : "male"}>
                    {t("male")}
                  </MenuItemStyled>
                </SelectStyled>
                <FormHelperText error>{errors.gender?.message}</FormHelperText>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "space-between",
              columnGap: "15px",
              width: "100%",
              px: { md: "23px", sm: "0px" },
              minHeight: "50px",
            }}
          >
            <Button
              sx={{
                width: "20%",
                [theme.breakpoints.down("md")]: {
                  width: "30%",
                },
                borderRadius: "16px",
                background: "#Fff",
                border: "1px solid #F9B700",
              }}
              onClick={() => navigate("/")}
            >
              <Typography sx={{ color: "#081937" }}>{t("Back")}</Typography>
            </Button>
            <Button
              sx={{
                width: "20%",
                [theme.breakpoints.down("md")]: {
                  width: "30%",
                },
                borderRadius: "16px",
                background: "#081937",
                color: "white",
                "&.Mui-disabled": {
                  backgroundColor: "#818ea3",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor: "#31466b",
                  color: "white",
                },
              }}
              onClick={() => handleSubmit(hanldeCreate)()}
              type="submit"
            >
              <Typography sx={{ color: "background.main" }}>
                {t("Next")}
              </Typography>
            </Button>
          </Box>
          <Box>
            {Object.entries(errors).map(([key, value]) => (
              <span
                style={{
                  color: "red",
                  paddingInlineStart: "25px",
                  marginTop: "5px",
                }}
              >
                {value?.message}
                <br />
              </span>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Foreigner;
