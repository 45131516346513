import { _commisiion } from "api/commisiion/commisiion";
import { useQuery } from "react-query";

export const useCommisiion = () => {
    const { data, isLoading } = useQuery(
        ["commisiion"],
        () => _commisiion.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};