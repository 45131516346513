import { useQuery } from "react-query";
import { _categories, _SubCategories } from "api/categories/categories";

export const useCategory = () => {
    const { data, isLoading, refetch } = useQuery(
        ["category"],
        () => _categories.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
        refetch
    };
};

export const useSubCategory = (id) => {
    const { data, isLoading, refetch } = useQuery(
        ["SubCategories"],
        () => _SubCategories.index(id).then((res) => res),
        {
            keepPreviousData: true,
        }
    )
    return {
        data,
        isLoading,
        refetch
    }
};
