// import { _AuthApi } from "api/auth";
import Swal from "sweetalert2";
import { _axios as Axios } from "../interceptor/http-config";

export const HttpResponseInterceptor = (navigate, enqueueSnackbar) => {
  const lang = localStorage.getItem("i18nextLng");
  Axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      switch (response?.config?.method) {
        case "post":
          Swal.fire({
            icon: "success",
            title: lang === "ar" ? "تم اتمام العملية بنجاح" : "success",
            text:
              lang === "ar"
                ? "تم اتمام العملية بنجاح سيتم اعلامكم بإرسال رسالة نصية تحوي رقم الحساب وكلمة المرور"
                : "The process has been completed successfully. You will be notified by sending a text message containing the account number and password",
            confirmButtonColor: "#081937",
            confirmButtonText: "ok",
          });
          break;
        case "put":
          enqueueSnackbar("Updated", response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;
        case "patch":
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;
        case "delete":
          enqueueSnackbar("Deleted", response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;

        default:
          break;
      }

      return response;
    },
    function (error) {
      // make a copy of the original request to do it again incase we need to refresh the token

      switch (error?.response?.status) {
        case 404:
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;

        case 422:
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;

        case 500:
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;

        case 405:
          Object.keys(error.response.data.message).map((key) =>
            enqueueSnackbar(error.response.data.message[key], {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            })
          );
          break;

        case 401:
          navigate("/");
          // _AuthApi.destroyToken();
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;

        case 403:
          // _AuthApi.destroyToken();
          navigate("/");
          break;

        // case 400:
        default:
          enqueueSnackbar("SERVER ERROR", {
            variant: "warning",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          break;
      }

      return Promise.reject(error);
    }
  );
};

// hello
