//* Mui import
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { TextareaAutosize } from "@material-ui/core";
import { FileUploadOutlined } from "@mui/icons-material";
//* Hooks
import { useGeneralCreate } from "./hooks/useGeneralCreate";
import { Controller } from "react-hook-form";
//* components
import { TextFieldStyled } from "components/styled/TextField";
import CustomDialog from "components/Dialog";
import MoneyDialog from "../../components/MoneyDialog";
import { Link } from "react-router-dom";
import { Home } from "react-feather";

const General = () => {
  const {
    hanldeCreate,
    register,
    handleSubmit,
    t,
    errors,
    details,
    setValue,
    random,
    open,
    handleImage,
    handleBackImage,
    control,
    myFormData,
    handleCloseDialog,
    Dopen,
    handleClose,
    setDopen,
    theme,
    navigate,
    getOptionLabel,
    options,
    subcategory_id,
    categoryValue,
    findNameById,
    sub_category,
    backImageItem,
    FrontImageItem,
    trigger,
    setImagesData,
  } = useGeneralCreate();

  return (
    <>
      <div className="nav home">
        <Link to={"/"}>
          <button>
            <Home />
          </button>
        </Link>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pb: "6vw",
        }}
      >
        <Box
          sx={{
            p: { sm: "5px", md: "24px" },
            width: { sm: "100%", md: "80%" },
          }}
        >
          <Box component="form">
            <Grid container spacing={1}>
              {/*//* category */}
              <Grid
                item
                md={6}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("category_id")}
                  </Typography>{" "}
                  <Typography variant="span" sx={{ color: "error.main" }}>
                    *
                  </Typography>
                </Box>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  fullWidth
                  loading
                  onChange={(event, newValue) => {
                    trigger("category_id");
                    setValue("category_id", String(newValue?.id));
                  }}
                  options={options}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  defaultValue={categoryValue ? categoryValue : null}
                  openOnFocus
                  renderInput={(params) => {
                    return (
                      <TextFieldStyled
                        {...params}
                        name="category_id"
                        error={errors.category_id?.message ? true : false}
                        helperText={errors.category_id?.message || ""}
                        sx={{
                          color: "text.main",
                          label: { color: "text.main" },
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                >
                  {options?.map((option) => (
                    <div key={option.id}>{option?.name}</div>
                  ))}
                </Autocomplete>
              </Grid>
              {/*//* subcategory_id */}
              <Grid
                item
                md={6}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("subcategory_id")}
                  </Typography>{" "}
                  <Typography variant="span" sx={{ color: "error.main" }}>
                    *
                  </Typography>
                </Box>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  loading
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue("subcategory_id", String(newValue?.id));
                    trigger("subcategory_id");
                  }}
                  options={subcategory_id}
                  getOptionLabel={(option) =>
                    option?.name || findNameById(subcategory_id, sub_category)
                  }
                  defaultValue={sub_category ? sub_category : null}
                  renderInput={(params) => {
                    return (
                      <TextFieldStyled
                        {...params}
                        name="subcategory_id"
                        error={errors.subcategory_id?.message ? true : false}
                        helperText={errors.subcategory_id?.message || ""}
                        onChange={() => trigger("subcategory_id")}
                        sx={{
                          color: "text.main",
                          label: { color: "text.main" },
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                >
                  {options?.map((option) => (
                    <div key={option.id}>{option.name}</div>
                  ))}
                </Autocomplete>
              </Grid>
              {/* //*work_description */}
              <Grid
                item
                md={12}
                xs={12}
                sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography variant="inputTitle">
                    {t("work_description")}
                  </Typography>
                </Box>
                <TextareaAutosize
                  variant="outlined"
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                    padding: "10px",
                    fontSize: "1rem",
                  }}
                  minRows={5}
                  maxLength={30}
                  type={"work_description"}
                  placeholder={t("work_description")}
                  name={t("work_description")}
                  {...register("work_description")}
                  defaultValue={
                    myFormData ? myFormData["work_description"] : null
                  }
                />
                <Typography sx={{ color: "error.main" }}>
                  {errors.work_description?.message}
                </Typography>
              </Grid>

              {/*  //* details */}
              {details.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  md={index === 2 ? 12 : 6}
                  sx={{ p: "10px 0px", px: { sm: "5px", md: "24px" } }}
                >
                  <Box sx={{ margin: "0 0 8px 5px" }}>
                    <Typography variant="inputTitle">{item.head}</Typography>{" "}
                    <Typography variant="span" sx={{ color: "error.main" }}>
                      *
                    </Typography>
                  </Box>
                  <Controller
                    control={control}
                    name={item.name}
                    {...register(item.register)}
                    render={({ field }) => (
                      <TextFieldStyled
                        variant="outlined"
                        style={{ width: "100%", borderRadius: "16px" }}
                        type={item.type}
                        placeholder={item.defaultValue}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger(item.name);
                        }}
                        disabled={item.name === "nationality" ? true : false}
                        error={errors[item.error]?.message ? true : false}
                        helperText={errors[item.helperText]?.message || ""}
                        defaultValue={item.defaultValue}
                      />
                    )}
                  />
                  {item.register === "phone_number" ? (
                    <FormHelperText sx={{ px: "10px" }}>
                      {t("Must start with 9639 ,Must be 12 numbers")}
                    </FormHelperText>
                  ) : null}
                  {item.register === "telephone_number" ? (
                    <FormHelperText sx={{ px: "10px" }}>
                      {t("Must be 10 numbers")}
                    </FormHelperText>
                  ) : null}
                </Grid>
              ))}
              {/* //*front_id_face */}
              <Grid
                item
                xs={12}
                sx={{
                  p: "10px 0px",
                  px: { sm: "5px", md: "24px" },
                  color: "primary.main",
                }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography
                    variant="inputTitle"
                    sx={{ color: "primary.main" }}
                  >
                    {t("front_id_face")}
                  </Typography>{" "}
                  <Typography variant="span" sx={{ color: "error.main" }}>
                    *
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name={"front_id_face"}
                  {...register("front_id_face")}
                  error={errors.front_id_face?.message}
                  helperText={errors.front_id_face?.message || ""}
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="outlined"
                      style={{
                        width: "100%",
                        borderRadius: "16px",
                        fontSize: "1rem",
                      }}
                      type={"file"}
                      placeholder={t("front_id_face")}
                      onChange={(e) => {
                        handleImage(e);
                        field.onChange(e.target.files);
                        setValue(e.target.value);
                        trigger("front_id_face");
                      }}
                      InputProps={{
                        endAdornment: (
                          <FileUploadOutlined sx={{ width: "4rem" }} />
                        ),
                      }}
                      inputProps={{
                        accept: "image/*",
                      }}
                    />
                  )}
                />
                <p style={{ color: "Gray" }}>
                  {t("Allowed file types:")}JPG PNG JPEG TIFF SVG .
                </p>
                <p style={{ color: "red" }}>{errors.front_id_face?.message}</p>
                <Box sx={{ width: "100%" }}>
                  {FrontImageItem ? (
                    <img
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "contain",
                        margin: " 0 auto",
                      }}
                      src={FrontImageItem.FrontImageLink}
                      alt=" "
                    />
                  ) : null}
                </Box>
              </Grid>
              {/* //* back_id_face */}
              <Grid
                item
                xs={12}
                sx={{
                  p: "10px 0px",
                  px: { sm: "5px", md: "24px" },
                  color: "primary.main",
                }}
              >
                <Box sx={{ margin: "0 0 8px 5px" }}>
                  <Typography
                    variant="inputTitle"
                    sx={{ color: "primary.main" }}
                  >
                    {t("back_id_face")}
                  </Typography>{" "}
                  <Typography variant="span" sx={{ color: "error.main" }}>
                    *
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name={"back_id_face"}
                  {...register("back_id_face")}
                  error={errors.back_id_face?.message ? true : false}
                  helperText={errors.back_id_face?.message || ""}
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="outlined"
                      style={{
                        width: "100%",
                        borderRadius: "16px",
                        fontSize: "1rem",
                      }}
                      type={"file"}
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        setValue(e.target.value);
                        trigger("back_id_face");
                        handleBackImage(e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <FileUploadOutlined sx={{ width: "4rem" }} />
                        ),
                      }}
                      inputProps={{
                        accept: "image/*",
                      }}
                    />
                  )}
                />
                <p style={{ color: "Gray" }}>
                  {t("Allowed file types:")}JPG PNG JPEG TIFF SVG .
                </p>
                <p style={{ color: "red" }}>{errors.back_id_face?.message}</p>
                <Box sx={{ width: "100%" }}>
                  {backImageItem ? (
                    <img
                      style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "contain",
                        margin: " 0 auto",
                      }}
                      src={backImageItem.BackImageLink}
                      alt=" "
                    />
                  ) : null}
                </Box>
              </Grid>
              {/* //* code  */}
              <Grid
                item
                xs={12}
                sx={{
                  p: "10px 0px",
                  px: { sm: "5px", md: "24px" },
                  color: "primary.main",
                }}
              >
                <Box
                  sx={{ margin: "0 0 8px 5px" }}
                  style={{ userSelect: "none" }}
                >
                  <Typography variant="inputTitle">
                    {t("code")}{" "}
                    <Typography variant="span" sx={{ color: "error.main" }}>
                      *
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        color: "error.main",
                        fontSize: "1.5rem",
                        fontFamily: "Roboto Slab !important",
                        paddingInlineStart: "20px",
                      }}
                    >
                      {random}
                    </Typography>
                  </Typography>
                </Box>
                <TextFieldStyled
                  variant="outlined"
                  style={{ width: "100%", borderRadius: "16px" }}
                  type="text"
                  placeholder={t("code")}
                  {...register("code")}
                  error={errors.code?.message ? true : false}
                  helperText={errors.code?.message || ""}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "space-between",
              columnGap: "15px",
              width: "100%",
              px: { md: "23px", sm: "0px" },
              minHeight: "50px",
            }}
          >
            <Button
              sx={{
                width: "20%",
                [theme.breakpoints.down("md")]: {
                  width: "30%",
                },
                borderRadius: "16px",
                background: "#Fff",
                border: "1px solid #F9B700",
              }}
              onClick={() => {
                navigate(-1);
                if (!myFormData.subcategory_id) setImagesData([]);
              }}
            >
              <Typography sx={{ color: "#081937" }}>{t("Back")}</Typography>
            </Button>
            <Button
              sx={{
                width: "20%",
                [theme.breakpoints.down("md")]: {
                  width: "30%",
                },
                borderRadius: "16px",
                background: "#081937",
                color: "white",
                "&.Mui-disabled": {
                  backgroundColor: "#818ea3",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor: "#31466b",
                  color: "white",
                },
              }}
              onClick={() => handleSubmit(hanldeCreate)()}
              type="submit"
            >
              <Typography sx={{ color: "background.main" }}>
                {t("Next")}
              </Typography>
            </Button>
          </Box>

          {/* //* error messages */}
          <Box>
            {Object.entries(errors).map(([key, value]) => (
              <span
                key={key}
                style={{
                  color: "red",
                  paddingInlineStart: "25px",
                  marginTop: "5px",
                }}
              >
                {value?.message}
                <br />
              </span>
            ))}
          </Box>

          {/* // * dialogs */}
          <CustomDialog setDOpen={setDopen} onClose={handleClose} open={open} />
          <MoneyDialog onDClose={handleCloseDialog} Dopen={Dopen} accountType={myFormData?.account_type}/>
        </Box>
      </Box>
    </>
  );
};
export default General;
