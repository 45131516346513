//* React
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

//* Hooks
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCountries } from "hooks/countries/useCountries";

//* Third party components
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { dataStore } from "store/dataStore";

export const useForeignerCreate = () => {
  // * States
  const [FormData, setFormData] = dataStore((state) => [
    state.FormData,
    state.setFormData,
  ]);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  //* Const
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation("index");

  //* Hooks
  const { data } = useCountries();
  const theme = useTheme();
  const navigate = useNavigate();

  // * handle schema error messages
  function getErrorMessage(name) {
    const msg = name?.replaceAll("_", " ");
    return `${msg}: ${t("is required")}`;
  }

  let schema = yup.object().shape({
    first_name: yup
      .string()
      .required(getErrorMessage(t("first_name")))
      .test("arabic-only", t("Only Arabic letters are allowed"), (value) => {
        return /^[\u0600-\u06FF\s]+$/.test(value);
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    type_id: yup.string().required(t("Account type is required")),
    branch_id: yup.string().required(t("Branch-Error")),
    last_name: yup
      .string()
      .required(getErrorMessage(t("last_name")))
      .test("arabic-only", t("Only Arabic letters are allowed"), (value) => {
        return /^[\u0600-\u06FF\s]+$/.test(value);
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    grandfather_name: yup
      .string()
      .test("arabic-only", t("Only Arabic letters are allowed"), (v) => {
        return v === undefined || v === "" || /^[\u0600-\u06FF\s]+$/.test(v);
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === undefined || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === undefined || !/^\s/.test(v)
      ),

    father_name: yup
      .string()
      .test("arabic-only", t("Only Arabic letters are allowed"), (v) => {
        return v === "" || v === undefined || /^[\u0600-\u06FF\s]+$/.test(v);
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || v === undefined || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || v === undefined || !/^\s/.test(v)
      ),

    mother_name: yup
      .string()
      .required(getErrorMessage(t("Mother name")))
      .test(
        "arabic-only",
        t("Only Arabic letters are allowed"),
        (v) => v === "" || /^[\u0600-\u06FF\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/^\s/.test(v)
      ),

    mother_name_en: yup
      .string()
      .required(getErrorMessage(t("Mother name english")))
      .test("arabic-only", t("Only English letters are allowed"), (v) =>
        /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    first_name_en: yup
      .string()
      .test("arabic-only", t("Only English letters are allowed"), (v) => {
        return v === "" || /^[A-Za-z\s]+$/.test(v);
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/^\s/.test(v)
      ),

    last_name_en: yup
      .string()
      .test(
        "english-only",
        t("Only English letters are allowed"),
        (v) => v === "" || /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/^\s/.test(v)
      ),
    father_name_en: yup
      .string()
      .test(
        "arabic-only",
        t("Only English letters are allowed"),
        (v) => v === "" || /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/^\s/.test(v)
      ),

    grandfather_name_en: yup
      .string()
      .test(
        "arabic-only",
        t("Only English letters are allowed"),
        (v) => v === "" || /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => v === "" || !/^\s/.test(v)
      ),

    Nationality: yup
      .string()
      .required(getErrorMessage(t("nationality")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    passport_number: yup
      .string()
      .required(getErrorMessage(t("passport_number")))
      .matches(
        /^[A-Za-z0-9]*$/,
        t("Only English letters and numbers are allowed")
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    place_of_birth: yup
      .string()
      .required(getErrorMessage(t("place_of_birth")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    Place_of_residence: yup
      .string()
      .required(getErrorMessage(t("place_of_residence")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    address: yup
      .string()
      .required(getErrorMessage(t("address")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      )
      .max(59, t("address must be at most 60 characters")),

    gender: yup.string().required(getErrorMessage(t("gender"))),

    birthdate: yup
      .date()
      .max(new Date(), t("Birthdate cannot be in the future"))
      .test(
        "is-old-enough",
        t("You must be at least 18 years old"),
        function (value) {
          const currentDate = new Date();
          const birthdate = new Date(value);
          const minAgeDate = new Date(
            currentDate.getFullYear() - 18,
            currentDate.getMonth(),
            currentDate.getDate()
          );
          return birthdate <= minAgeDate;
        }
      )
      .required(t("place_of_birth")),
  });

  const {
    setValue,
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: schema.cast(FormData),
    resolver: yupResolver(schema),
  });

  const handleCancel = () => navigate(-1);

  const handleReset = () => {
    const form = document.querySelector("form");
    if (form) form.reset();
  };

  //* الحقول الاختيارية =================================
  // اسم الاب اختياري
  // اسم الجد اختياري
  // الاسم باللغة الإنكليزية اختياري
  // الكنية باللغة الإنكليزية اختياري
  // اسم الاب باللغة الإنكليزية اختياري
  // اسم الجد باللغة الإنكليزية اختياري

  const fields = [
    ["first_name", "text", "required"],
    ["first_name_en", "text", "notRequired"],

    ["last_name", "text", "required"],
    ["last_name_en", "text", "notRequired"],

    ["father_name", "text", "notRequired"],
    ["father_name_en", "text", "notRequired"],

    ["mother_name", "text", "required"],
    ["mother_name_en", "text", "required"],

    ["grandfather_name", "text", "notRequired"],
    ["grandfather_name_en", "text", "notRequired"],

    ["passport_number", "text", "required"],
    ["birthdate", "date", "required"],
    ["place_of_birth", "text", "required"],
  ];

  const hanldeCreate = (input) => {
    const birthdateDate = input.birthdate; //* get birthdate value
    //* convert birthdate value into dd-mm-yyyy
    const formattedBirthdate = `${birthdateDate.getFullYear()}-${(
      birthdateDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${birthdateDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const inputWithoutBirthDate = { ...input }; //
    for (const key in input) {
      if (
        inputWithoutBirthDate[key] === "" ||
        inputWithoutBirthDate[key] === undefined
      ) {
        delete inputWithoutBirthDate[key];
      }
    }
    delete inputWithoutBirthDate.birthdate; //* remove birthdate from input
    setFormData(inputWithoutBirthDate); //* add new input Without BirthDate data to state
    setFormData({ birthdate: formattedBirthdate }); //* add date with format dd-mm-yyyy to state

    setLoading(true);
    navigate("/General/Foreigner");
  };

  const items = () => {
    const data = [];
    fields.map((item) => {
      const key = item[0];
      const type = item[1];
      const required = item[2];
      var element = {
        head: key.replaceAll("_", " "),
        type: type,
        placeholder: key.replaceAll("_", " "),
        name: key,
        register: key,
        error: key,
        helperText: key,
        required: required,
        defaultValue: FormData ? FormData[key] : null,
      };
      data.push(element);
    });
    setDetails(data);
  };

  useEffect(() => {
    items();
    window.scrollTo(0, 0);
  }, []);

  return {
    handleCancel,
    handleReset,
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    control,
    watch,
    FormData,
    data,
    lang,
    navigate,
    theme,
    trigger,
  };
};
