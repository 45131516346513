//
// Colors

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const colors = {
  background: {
    main: "#19212e",
    paper: "#273246",
    login: '#00000078'
  },
  extrenal: {
    main: "#19212e",
  },
  origin: {
    main: "#4c566a",
  },
  edit: {
    main: "#ced4da",
  },
  text: {
    main: "#ced4da",
  },
  primary: {
    main: "#74aee9",
  },
  inactive: {
    main: "rgba(145, 158, 171, 0.16)",
  },
  darkGray: {
    main: "rgb(156 172 189)",
  },
  lightGray: {
    main: "#b4bdcf",
  },
  warning: {
    main: "rgb(255, 193, 7)",
  },
  error: {
    main: "#702632",
  },
  success: {
    main: "rgb(84, 214, 44)",
  },
  card: {
    main: "#212d40",
  },
};

export const typography = {
  fontFamily: [].join(","),
  button: {
    fontSize: "14px",
  },

  h1: {
    fontSize: "64px",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "48px",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "40px",
    fontWeight: "bold",
  },
  h4: {
    fontSize: 26,
    fontWeight: "bold",
  },
  h5: {
    fontSize: 23,
    fontWeight: "bold",
  },
  h6: {
    fontSize: 20,
  },

  subtitle1: {
    fontSize: "0.955rem",
  },
  subtitle2: {
    fontSize: 12,
  },

  body1: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  body2: {
    fontSize: "14px",
  },
  caption: {
    // fontSize: "12px",
  },
  inputTitle: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
};

export const darkThemeOptions = {
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              transition: "250ms",
            },
            color: "#ffffff",
            backgroundColor: "inherit",
            "&.Mui-selected ": {
              color: "#ffff",
              backgroundColor: "#2196F3",
            },
          },
        },
      },
    },

    // MuiAutocomplete: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiAutocomplete-option": {
    //         color: colors.text.main,
    //       },
    //     },
    //     inputRoot: {
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         borderColor: colors.text.main,
    //       },
    //       '& .MuiInputBase-input': {
    //         '&::placeholder': {
    //           color: colors.text.main,
    //         },
    //       },

    //     },
    //     option: {
    //       backgroundColor: colors.background.main,
    //       '&[data-focus="true"]': {
    //         backgroundColor: colors.background.main,
    //         color: colors.text.main,
    //       },
    //       '&[aria-selected="true"]': {
    //         backgroundColor: colors.background.main,
    //         color: colors.text.main,
    //       },
    //     },
    //     tag: {
    //       backgroundColor: colors.background.main,
    //       color: colors.text.main,
    //     },
    //   },
    // },

    MuiTableHead: {
      styleOverrides: {
        root: {
          "&.MuiTableHead-root ": {
            backgroundColor: colors.inactive.main,
          },
          ".MuiTableCell-root.MuiTableCell-head": {
            color: colors.text.main,
            textTransform: "capitalize",
          },
          ".MuiTableCell-root.MuiTableCell-head:first-of-type": {
            borderRadius: "8px 0 0 8px",
          },
          ".MuiTableCell-root.MuiTableCell-head:last-of-type": {
            borderRadius: "0 8px 8px 0",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root.MuiTableCell-body": {
            color: colors.text.main,
            ...typography.body2,
            borderBottom: "none",
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(189, 200, 240,0.2)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
        },
      },
    },

  },

  palette: {
    mode: "dark",
    ...colors,
  },
  typography: {
    ...typography,
  },
};
