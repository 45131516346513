import { _Countries } from "api/countries/countries";
import { useQuery } from "react-query";

export const useCountries = () => {
    const { data, isLoading } = useQuery(
        ["Countries"],
        () => _Countries.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};