//* React
import { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
//* Third party libraries
import * as yup from "yup";
import { useTranslation } from "react-i18next";
//* hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCategory, useSubCategory } from "hooks/Home/useCategory";
//* components
import { ImageDataContext } from "components/ImageDataContext";
import { dataStore } from "store/dataStore";

export const useGeneralCreate = () => {
  //* states
  const [Dopen, setDopen] = useState(false);
  const [details, setDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [random, setRandom] = useState("");
  const [subcategory_id, setsubcategory_id] = useState([]);

  //* store
  const [myFormData, setmyFormData] = dataStore((state) => [
    state.FormData,
    state.setFormData,
  ]);
  //* variables
  const { t } = useTranslation("index");
  const theme = useTheme();
  const { imagesData, setImagesData } = useContext(ImageDataContext);
  let categoryValue = myFormData ? Number(myFormData["category_id"]) : null;
  let sub_category = myFormData ? Number(myFormData["subcategory_id"]) : null;
  let backImageItem = imagesData.find((item) =>
    item.hasOwnProperty("BackImageLink")
  );
  let FrontImageItem = imagesData.find((item) =>
    item.hasOwnProperty("FrontImageLink")
  );

  //* hooks
  const params = useParams();
  const navigate = useNavigate();
  const { data, refetch } = useCategory();
  const { data: subdata, refetch: refetchSub } = useSubCategory();
  const handleCloseDialog = () => setDopen(false);
  const handleClose = () => setOpen(false);

  function getErrorMessage(name) {
    const msg = name?.replaceAll("_", " ");
    return `${msg}: ${t("is required")}`;
  }

  function generateRandomString() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setRandom(result);
    return result;
  }
  // syrian and palistaine validation
  let schema = yup.object().shape({
    work_description: yup
      .string()
      .max(30, t("Work description must be at most 30 characters"))
      .strict(true)
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    phone_number: yup
      .string()
      .required(getErrorMessage(t("phone_number")))
      .matches(
        /^9639\d{8}$/,
        t("phone_number") + " " + t("Must start with 9639 ,Must be 12 numbers")
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    category_id: yup.string().required(getErrorMessage(t("category_id"))),
    subcategory_id: yup.string().required(getErrorMessage(t("subcategory_id"))),
    telephone_number: yup
      .string()
      .required(getErrorMessage(t("telephone_number")))
      .matches(/^[0-9]{10}$/, t("Must be exactly 10 characters"))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    code: yup
      .string()
      .required(getErrorMessage(t("code")))
      .test("match", t("Please enter the correct code"), (value) => {
        return value === random;
      })
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    front_id_face: yup
      .mixed()
      .required(t("front_id_face"))
      .test(
        "size",
        t("Please select an image file less than 3MB"),
        (value) => value && value[0] && value[0].size <= 3 * 1024 * 1024
      )
      .test(
        "fileType",
        t("Pleas select an image file"),
        (value) => value && value[0] && value[0].type.includes("image/")
      ),
    back_id_face: yup
      .mixed()
      .required(t("back_id_face"))
      .test(
        "size",
        t("Please select an image file less than 3MB"),
        (value) => value && value[0] && value[0].size <= 3 * 1024 * 1024
      )
      .test(
        "fileType",
        t("Pleas select an image file"),
        (value) => value && value[0] && value[0].type.includes("image/")
      ),
  });

  // Foreigner validation
  let Foreigner = yup.object().shape({
    work_description: yup
      .string()
      .max(30, t("Work description must be at most 30 characters"))
      .strict(true),
    phone_number: yup
      .string()
      .required(getErrorMessage(t("phone_number")))
      .matches(
        /^9639\d{8}$/,
        t("phone_number") + " " + t("Must start with 9639, Must be 12 numbers")
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    category_id: yup.string().required(getErrorMessage(t("category_id"))),
    subcategory_id: yup.string().required(getErrorMessage(t("subcategory_id"))),
    telephone_number: yup
      .string()
      .required(getErrorMessage(t("telephone_number")))
      .matches(/^[0-9]{10}$/, t("Must be exactly 10 characters"))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    code: yup
      .string()
      .required(getErrorMessage(t("code")))
      .test("match", t("Please enter the correct code"), (value) => {
        return value === random;
      }),
    front_id_face: yup
      .mixed()
      .required(t("front_id_face"))
      .test(
        "size",
        t("Please select an image file less than 3MB"),
        (value) => value && value[0] && value[0].size <= 3 * 1024 * 1024
      )
      .test(
        "fileType",
        t("Pleas select an image file"),
        (value) => value && value[0] && value[0].type.includes("image/")
      ),

    back_id_face: yup
      .mixed()
      .required(t("back_id_face"))
      .test("size", t("Please select an image file less than 3MB"), (value) => {
        return value && value[0] && value[0].size <= 3 * 1024 * 1024;
      })
      .test(
        "fileType",
        t("Pleas select an image file"),
        (value) => value && value[0] && value[0].type.includes("image/")
      ),
  });

  //* choose schema pased i=on user
  const returnSchema = () => {
    if (params.type !== "Foreigner") return schema;
    else return Foreigner;
  };

  const {
    setValue,
    register,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: schema.cast(myFormData),
    resolver: yupResolver(returnSchema()),
  });

  //*handle front Id Image to preview and store in use context
  const handleImage = (e) => {
    if (e.target.files.length > 0) {
      const index = imagesData.findIndex((image) => image.FrontImage);
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        if (file.size > 3 * 1024 * 1024) return;
        reader.onload = (e) => {
          // Find the index of the existing image data
          if (index !== -1) {
            // Update the existing image data
            setImagesData((prev) => {
              const updatedImagesData = [...prev];
              updatedImagesData[index] = {
                FrontImage: file,
                FrontImageLink: e.target.result,
              };
              return updatedImagesData;
            });
          } else {
            // Add new image data
            setImagesData((prev) => [
              ...prev,
              { FrontImage: file, FrontImageLink: e.target.result },
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  //*handle front Id Image to preview and store in use context
  const handleBackImage = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        if (file.size > 3 * 1024 * 1024) return;
        reader.onload = (e) => {
          // Find the index of the existing image data
          const index = imagesData.findIndex((image) => image.BackImage);
          if (index !== -1) {
            // Update the existing image data
            setImagesData((prev) => {
              const updatedImagesData = [...prev];
              updatedImagesData[index] = {
                BackImage: file,
                BackImageLink: e.target.result,
              };
              return updatedImagesData;
            });
          } else {
            // Add new image data
            setImagesData((prev) => [
              ...prev,
              { BackImage: file, BackImageLink: e.target.result },
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // * submit data
  const hanldeCreate = (input) => {
    const inputWithoutimages = { ...input }; //
    delete inputWithoutimages.back_id_face;
    delete inputWithoutimages.front_id_face;

    for (const key in input) {
      if (key.startsWith("C:\\fakepath\\")) {
        delete inputWithoutimages[key];
        delete inputWithoutimages[key];
      }
      if (
        inputWithoutimages[key] === "" ||
        inputWithoutimages[key] === undefined
      ) {
        delete inputWithoutimages[key];
      }
    }

    setmyFormData(inputWithoutimages);
    setOpen(true);
  };

  //* get all data and find selected item based on id to preview text disctiption
  function findNameById(dat, id) {
    for (let i = 0; i < dat.length; i++) {
      if (dat[i].id === id) {
        return dat[i].name;
      }
    }
    return null;
  }

  const getOptionLabel = (option) => {
    if (option?.name) {
      return option?.name;
    }
    if (categoryValue) {
      return findNameById(options, categoryValue);
    } else return "IDK";
  };

  useMemo(() => {
    refetch();
    refetchSub();
    if (data) setOptions(data?.categories);
    if (subdata) setsubcategory_id(subdata?.sub_category);
  }, [data, subdata, refetch, refetchSub]);

  //* render inputs
  useEffect(() => {
    // if (params.type !== 'Foreigner') fields.push(["address", "text"])
    generateRandomString();
    const fields = [
      ["phone_number", "text"],
      ["telephone_number", "text"],
    ];
    const data = [];
    fields.map((item) => {
      const key = item[0];
      const type = item[1];
      var element = {
        head: t(key).replaceAll("_", " "),
        type: type,
        placeholder: t(key).replaceAll("_", " "),
        name: key,
        register: key,
        error: key,
        helperText: key,
        defaultValue: myFormData ? myFormData[key] : null,
      };
      data.push(element);
      return element;
    });
    setDetails(data);
    window.scrollTo(0, 0);
    if (myFormData === undefined) {
      navigate("/");
    }
  }, [myFormData, params, navigate, t]);

  return {
    subcategory_id,
    categoryValue,
    sub_category,
    backImageItem,
    FrontImageItem,
    errors,
    details,
    random,
    open,
    control,
    myFormData,
    Dopen,
    options,
    theme,
    hanldeCreate,
    register,
    handleSubmit,
    t,
    findNameById,
    setValue,
    handleImage,
    handleBackImage,
    handleCloseDialog,
    handleClose,
    setDopen,
    navigate,
    getOptionLabel,
    setImagesData,
    trigger,
  };
};
