import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCommisiion } from "hooks/commisiion/commisiion";
import { dataStore } from "store/dataStore";

const CustomDialog = ({ onDClose, Dopen, accountType }) => {
  const { t } = useTranslation("index");
  const accountTypeStore = dataStore((state) => state.accountType);
  const params = useParams();
  const navigate = useNavigate("i18next");
  const handleClose = () => {
    if (params.type === "syrian") {
      navigate("/confirmation/syrian");
    } else if (params.type === "Foreigner") navigate("/confirmation/foreigner");
    else if (params.type === "palestine") navigate("/confirmation/palestine");

    onDClose();
  };

  const { data } = useCommisiion();
  return (
    <React.Fragment>
      <Dialog open={Dopen} onClose={onDClose} PaperProps={{ className: "red" }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: "primary.main",
            px: "5vw",
            pt: "5vw",
            textAlign: "center",
          }}
        >
          {t("Customer message")}
        </DialogTitle>
        <DialogContent sx={{ px: "5vw" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            <p style={{ textAlign: "center" }}>
              {data?.commission?.message}
              <br />
              {data?.commission?.amount}
            </p>
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            <p style={{ textAlign: "center" }}>
              {t("account-type")}:{accountTypeStore.title || ""}
            </p>
            <p style={{ textAlign: "center" }}>
              {accountTypeStore.description || ""}
            </p>
            <p style={{ textAlign: "center" }}>
              {!!accountType ? t("account-type-text") : ""}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", px: "5vw", pb: "5vw" }}>
          <Button
            color="primary"
            variant="filled"
            sx={{
              width: "100%",
              mt: "10px",
              color: "white",
              background: "#081937",
              "&.Mui-disabled": {
                backgroundColor: "#818ea3",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#31466b",
                color: "white",
              },
            }}
            onClick={handleClose}
          >
            {t("Submit")}
          </Button>
          <Button
            onClick={onDClose}
            sx={{
              width: "100%",
              mt: "10px",
              color: "text.primary",
              background: "#F9B700",
              "&.Mui-disabled": {
                backgroundColor: "#bfb9a7",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#f9d160",
              },
            }}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
