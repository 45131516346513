// user/dealership/index
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _categories = {
    index: async () => {
        return _axios
            .get(
                `api/user/category`
                , {
                    headers: {
                        ...HttpRequestInterceptor()
                    }
                })
            .then((res) => res.data);
    },
};

export const _SubCategories = {
    index: async (id) => {
        return _axios
            .get(
                `api/user/subcategory`
                , {
                    headers: {
                        ...HttpRequestInterceptor()

                    }
                })
            .then((res) => res.data);
    },
};
