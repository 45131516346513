import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export const Drawer = styled(MuiDrawer, {
})(({ theme }) => ({
  width: '22vw',

  // flexShrink: 0,
  // whiteSpace: "nowrap",
  // boxSizing: "border-box",
  [theme.breakpoints.down('sm')]: {
    width: 'inherit',
    flexShrink: 'unset',
    // height: "5vh"
  },
}));
