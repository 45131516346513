import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { _axios } from "interceptor/http-config";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { dataStore } from "store/dataStore";
import { MenuItemStyled, SelectStyled } from "./styled/TextField";

const SelectAccountType = ({ watch, register, errors }) => {
  const { t, i18n } = useTranslation("index");
  const setAccountType = dataStore((state) => state.setAccountType);
  const { data, isLoading, isError } = useQuery({
    queryKey: ["accountType", i18n.language],
    keepPreviousData: true,
    queryFn: async () => {
      const { data } = await _axios.get("/api/user/account-type");
      return data.account_types;
    },
  });
  const accountType = watch("type_id");
  useEffect(() => {
    if (data) {
      if (accountType) {
        setAccountType(
          data && data?.find((item) => item.id.toString() === accountType)
            ? {
                title: data?.find((i) => i.id.toString() === accountType)
                  ?.title,
                description: data?.find((i) => i.id.toString() === accountType)
                  ?.description,
              }
            : {
                title: "",
                description: "",
              }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return (
    <Grid
      item
      container
      xs={6}
      sx={{ p: "10px 0px", pr: { sm: "5px", md: "24px" }, width: "100%" }}
    >
      <Box sx={{ margin: "0 0 8px 5px" }}>
        <Typography variant="inputTitle">{t("account-type")}*</Typography>
      </Box>
      {isLoading ? (
        <SelectStyled
          variant="outlined"
          disabled
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          defaultValue={""}
        >
          <MenuItemStyled value=""></MenuItemStyled>
        </SelectStyled>
      ) : isError ? (
        <SelectStyled
          variant="outlined"
          disabled
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          defaultValue={""}
        >
          <MenuItemStyled value=""></MenuItemStyled>
        </SelectStyled>
      ) : (
        <SelectStyled
          key={isLoading}
          variant="outlined"
          disabled={isLoading || isError}
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          {...register("type_id")}
          error={errors.type_id?.message ? true : false}
          defaultValue={
            accountType
              ? data.find((i) => i.id.toString() === accountType)?.id.toString()
              : ""
          }
          helperText={errors.type_id?.message || ""}
        >
          {data.map((item) => (
            <MenuItemStyled key={item.id.toString()} value={item.id.toString()}>
              {item.title}
            </MenuItemStyled>
          ))}
        </SelectStyled>
      )}

      <FormHelperText error>{errors.type_id?.message}</FormHelperText>
      {data?.find((i) => i.id.toString() === watch("type_id")) ? (
        <FormHelperText key={watch("type_id")}>
          {
            data?.find((i) => i.id.toString() === watch("type_id"))
              .description
          }
        </FormHelperText>
      ) : null}
    </Grid>
  );
};

export default SelectAccountType;
