import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { dataStore } from "store/dataStore";
import { subYears } from "date-fns";
import { useTheme } from "@emotion/react";

export const useSyrianCreate = () => {
  //* hooks
  const { t } = useTranslation("index");
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  //* variables
  const [details, setDetails] = useState([]);
  const lang = localStorage.getItem("i18nextLng");

  //* palistain schema
  const baseSchema = yup.object().shape({
    type: yup.string(),
    //* arabic field
    first_name: yup
      .string()
      .required(getErrorMessage(t("first_name")))
      .test("arabic-only", t("Only Arabic letters are allowed"), (v) =>
        /^[\u0600-\u06FF\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    type_id: yup.string().required(t("Account type is required")),
    branch_id: yup.string().required(t("Branch-Error")),

    last_name: yup
      .string()
      .required(getErrorMessage(t("last_name")))
      .test("arabic-only", t("Only Arabic letters are allowed"), (v) =>
        /^[\u0600-\u06FF\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    father_name: yup
      .string()
      .required(getErrorMessage(t("father_name")))
      .test("arabic-only", t("Only Arabic letters are allowed"), (v) =>
        /^[\u0600-\u06FF\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    //* english fields
    last_name_en: yup
      .string()
      .required(getErrorMessage(t("last_name_en")))
      .test("arabic-only", t("Only English letters are allowed"), (v) =>
        /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    father_name_en: yup
      .string()
      .required(getErrorMessage(t("father_name_en")))
      .test("arabic-only", t("Only English letters are allowed"), (v) =>
        /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    first_name_en: yup
      .string()
      .required(getErrorMessage(t("first_name_en")))
      .test("arabic-only", t("Only English letters are allowed"), (v) =>
        /^[A-Za-z\s]+$/.test(v)
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),

    //* other fields
    mother_full_name: yup
      .string()
      .required(getErrorMessage(t("mother_full_name")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    gender: yup
      .string()
      .nullable()
      .required(getErrorMessage(t("gender")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    place_of_birth: yup
      .string()
      .required(getErrorMessage(t("place_of_birth")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    national_id_number: yup
      .string()
      .matches(/^[0-9]{11}$/, t("Must be 11 numbers"))
      .required(
        params.type === "1"
          ? getErrorMessage(t("national_id_number"))
          : getErrorMessage(t("National_id_number"))
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    registration_number: yup
      .string()
      .required(
        params.type === "1"
          ? getErrorMessage(t("registration number"))
          : getErrorMessage(t("Registration number"))
      )
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    place_of_residence: yup
      .string()
      .nullable()
      .required(getErrorMessage(t("place_of_residence")))
      .test(
        "is-null",
        getErrorMessage(t("place_of_residence")),
        (value) => value !== null
      ),
    identification_number: yup
      .string()
      .matches(/^[0-9]{8}$/, t("Must be 8 numbers"))
      .required(getErrorMessage(t("identification_number")))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
    birthdate: yup
      .date()
      .typeError(getErrorMessage(t("birthdate")))
      .required(getErrorMessage(t("birthdate")))
      .max(subYears(new Date(), 18), t("You must be at least 18 years old"))
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return new Date(originalValue.replace(/\//g, "-"));
        }
        return value;
      }),
    address: yup
      .string()
      .required(getErrorMessage(t("address")))
      .max(59, t("address must be at most 60 characters"))
      .test(
        "no-trailing-space",
        t("No trailing spaces allowed"),
        (v) => !/\s$/.test(v)
      )
      .test(
        "no-leading-space",
        t("No trailing spaces allowed"),
        (v) => !/^\s/.test(v)
      ),
  });

  const al_amaneh =
    params.type === "1"
      ? yup.object().shape({
          al_amaneh: yup
            .string()
            .required(getErrorMessage(t("al amaneh")))
            .test(
              "no-trailing-space",
              t("No trailing spaces allowed"),
              (v) => !/\s$/.test(v)
            )
            .test(
              "no-leading-space",
              t("No trailing spaces allowed"),
              (v) => !/^\s/.test(v)
            ),
        })
      : yup.object().shape({
          al_amaneh: yup
            .string()
            .required(getErrorMessage(t("Al amaneh")))
            .matches(/^[0-9]{5}$/, t("Familly card number must be 5 numbers."))
            .test(
              "no-trailing-space",
              t("No trailing spaces allowed"),
              (v) => !/\s$/.test(v)
            )
            .test(
              "no-leading-space",
              t("No trailing spaces allowed"),
              (v) => !/^\s/.test(v)
            ),
        });

  const schema = baseSchema.concat(al_amaneh);

  const [FormData, setFormData, resetFromData] = dataStore((state) => [
    state.FormData,
    state.setFormData,
    state.resetFromData,
  ]);

  const {
    setValue,
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: schema.cast(FormData),
    resolver: yupResolver(schema),
  });

  function getErrorMessage(name) {
    const msg = name?.replaceAll("_", " ");
    return `${msg}: ${t("is required")}`;
  }

  const hanldeCreate = (input) => {
    resetFromData(); //* reset the form if the user complete inly one step in the previous session
    const birthdateDate = input.birthdate; //* get birthdate value
    //* convert birthdate value into dd-mm-yyyy
    const formattedBirthdate = `${birthdateDate.getFullYear()}-${(
      birthdateDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${birthdateDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const inputWithoutBirthDate = { ...input };
    for (const key in input) {
      if (inputWithoutBirthDate[key] === "") {
        delete inputWithoutBirthDate[key];
      }
    }
    delete inputWithoutBirthDate.birthdate; //* remove birthdate from input
    setFormData(inputWithoutBirthDate); //* add new input Without BirthDate data to state
    setFormData({ birthdate: formattedBirthdate }); //* add date with format dd-mm-yyyy to state
    setFormData({
      nationality: params.type === "1" ? t("Syrian") : t("Palestinian Syrian"),
    });
    setLoading(true);
    if (params.type === "1") navigate("/general/syrian");
    else if (params.type === "2") navigate("/general/palestine");
  };

  //* render requierd fields
  useEffect(() => {
    const fields = [
      ["first_name", "text", "^[ء-ي]+$"],
      ["first_name_en", "text", "[A-Za-z]+"],

      ["last_name", "text", "[ء-ي]+"],
      ["last_name_en", "text", "[A-Za-z]+"],

      ["father_name", "text", "[ء-ي]+"],
      ["father_name_en", "text", "[A-Za-z]+"],

      ["mother_full_name", "text"],

      ["birthdate", "date"],
      ["place_of_birth", "text"],
      ["nationality", "text"], //*"الجنسية"
      ["national_id_number", "text"], //*"الرقم الوطني"
      ["registration_number", "text"], //* "محل ورقم القيد"
      ["al_amaneh", "text"],
      ["identification_number", "text"], //* رقم الهوية
    ];
    const data = [];
    fields.map((item) => {
      const key = item[0];
      const type = item[1];
      const pattern = item[2];
      var element = {
        head: key.replaceAll("_", " "),
        type: type,
        placeholder: key.replaceAll("_", " "),
        name: key,
        register: key,
        error: key,
        helperText: key,
        pattern: pattern,
        defaultValue: FormData ? FormData[key] : null,
      };
      data.push(element);
      return element;
    });
    setDetails(data);
    window.scrollTo(0, 0);
  }, [FormData]);

  return {
    hanldeCreate,
    register,
    handleSubmit,
    setValue,
    loading,
    t,
    errors,
    details,
    FormData,
    control,
    params,
    lang,
    navigate,
    theme,
    watch,
    trigger,
  };
};
