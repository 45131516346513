import { create } from "zustand";

export const dataStore = create((set) => ({
  FormData: undefined,
  accountType: {
    title: "",
    description: "",
  },
  branch: "",
  setAccountType: (newData) => {
    set((state) => ({ ...state, accountType: newData }));
  },
  setBranch: (newData) => {
    set((state) => ({ ...state, branch: newData }));
  },
  setFormData: (newData) => {
    set((state) => ({ FormData: { ...state.FormData, ...newData } }));
  },
  resetFromData: () => {
    set(() => ({
      FormData: undefined,
    }));
  },
}));
