import { useSnackbar } from "notistack";
import Confirmation from "pages/Confirmation/Confirmation";
import Foreigner from "pages/Foreigner/Foreigner";
import General from "pages/General/General";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { HttpRequestInterceptor } from "./interceptor/http-request.interceptor";
import { HttpResponseInterceptor } from "./interceptor/http-response.interceptor";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Syrian from "./pages/Syrians/Syrian";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingsStore";

function App() {
  // const [lang, setLang] = useState("en");
  // const [direction, setdirection] = useState();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const setDirection = settingsStore((state) => state.setDirection);

  useEffect(() => {
    HttpRequestInterceptor();
    HttpResponseInterceptor(navigate, enqueueSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lang = ["ar", "en"].includes(i18n.language) ? i18n.language : "ar";
    if (!["en", "ar"].includes(pathname.slice(1).split("/")[0])) {
      navigate(`/${lang}${pathname}`, { replace: true });
    } else {
      i18n.changeLanguage(pathname.slice(1).split("/")[0]);
      setDirection(pathname.slice(1).split("/")[0] === "en" ? "ltr" : "rtl");
    }
  }, [pathname, i18n, navigate, setDirection]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
  window.addEventListener("beforeunload", (event) => {
    event.preventDefault();
    event.returnValue =
      "Are you sure you want to leave? Any unsaved changes will be lost.";
  });
  return (
    <React.Suspense>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route path="/:lang" element={<Home />} />
              <Route path=":lang/syrian/:type" element={<Syrian />} />
              <Route path=":lang/foreigner" element={<Foreigner />} />
              <Route path=":lang/General/:type" element={<General />} />
              <Route
                path=":lang/Confirmation/:type"
                element={<Confirmation />}
              />
            </Route>
          </Routes>
        </div>
      </QueryClientProvider>
    </React.Suspense>
  );
}

export default App;
