import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { MenuItemStyled, SelectStyled } from "./styled/TextField";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import { useEffect } from "react";
import { dataStore } from "store/dataStore";

const SelectBranch = ({ register, errors, watch }) => {
  const { t, i18n } = useTranslation("index");
  const setBranch = dataStore((state) => state.setBranch);
  const { data, isLoading, isError } = useQuery({
    queryKey: ["branch", i18n.language],
    queryFn: async () => {
      const { data } = await _axios.get("/api/user/branch");
      return data.branches;
    },
  });

  const branch = watch("branch_id");
  useEffect(() => {
    if (branch) {
      setBranch(
        data?.find((item) => item.id.toString() === branch)?.title || ""
      );
    }
  }, [data, setBranch, branch]);

  return (
    <Grid
      item
      container
      xs={6}
      sx={{ p: "10px 0px", pr: { sm: "5px", md: "24px" }, width:"100%" }}
    >
      <Box sx={{ margin: "0 0 8px 5px" }}>
        <Typography variant="inputTitle">{t("Branch")}*</Typography>
      </Box>
      {isLoading ? (
        <SelectStyled
          variant="outlined"
          disabled
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          defaultValue={""}
        >
          <MenuItemStyled value=""></MenuItemStyled>
        </SelectStyled>
      ) : isError ? (
        <SelectStyled
          variant="outlined"
          disabled
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          defaultValue={""}
        >
          <MenuItemStyled value=""></MenuItemStyled>
        </SelectStyled>
      ) : (
        <SelectStyled
          key={isLoading}
          variant="outlined"
          disabled={isLoading || isError}
          style={{ width: "100%" }}
          sx={{
            color: "text.main",
            borderColor: "text.main",
            width: "100%",
          }}
          {...register("branch_id")}
          error={errors.branch_id?.message ? true : false}
          defaultValue={
            branch
              ? data.find((i) => i.id.toString() === branch)?.id.toString()
              : ""
          }
          helperText={errors.branch_id?.message || ""}
        >
          {data.map((item) => (
            <MenuItemStyled key={item.id.toString()} value={item.id.toString()}>
              {item.title}
            </MenuItemStyled>
          ))}
        </SelectStyled>
      )}
      <FormHelperText error>{errors.branch_id?.message}</FormHelperText>
    </Grid>
  );
};

export default SelectBranch;
