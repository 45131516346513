import { FormControlLabel, Typography } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomDialog = ({ onClose, open, setDOpen }) => {
  const { t } = useTranslation("index");
  const [checked, setChecked] = useState(false);
  const handleOpenDialog = () => {
    onClose();
    setDOpen(true);
  };
  const lang = localStorage.getItem("i18nextLng");
  const text = [
    "Bank: Bank of Jordan - Syria",
    "Digital account: It is a bank account that is created through the bank's websites and electronic transfer, which procedures and controls are determined by the bank.",
    "Customer: is the natural person wishing to create an electronic account with the bank.",
    "1-The mere fact that the customer registers his data on the bank's website to open the account, does not create any obligations on the bank towards the customer as long as he is not notified by the bank of opening the account",
    "2-It is not possible to open a digital account for persons who have a previous bank account with the bank",
    "3-The upper limit of any deposit in the digital account is SYP 5,000,000 (only five million Syrian pounds), and it is forbidden to carry out any operation that exceeds this limit (the upper limit of the account balance is 5,000,000 Syrian pounds).",
    "4-When using electronic channels and benefiting from them, the customer shall adhere to all instructions and the privacy policy within the purposes of opening the account declared to the bank. The bank has the right to restrict the use of accounts to the extent of the need of the account holder without prior notice, according to the Anti-Money Laundering and Terrorist Financing Commission’s Circular No. 277/18/p. dated 3/7/2024 To prevent the use of the mobile banking application to carry out electronic payment operations for the public as a profession.",
    "5-The account can be fed through bank transfers or cash through the bank's operating branches",
    "6-It is not possible to issue an ATM card for a digital account",
    "7-The digital account is treated as a current account and does not charge any interest on it",
    "8-The mobile phone number used to open the digital account must belong to one of the cellular companies licensed exclusively in Syria.",
    "9-It is not possible to open more than one digital account for the same customer and the same national number",
    "10-The customer is obligated to report to the bank to inform him of any change or modification to his authorized personal information",
    "11-The bank determines the commissions incurred for the services of this account, and it has the right to amend these commissions at any time as they are effective immediately without the need to inform the customer",
    "12-The client alone bears the taxes, expenses and commissions that fall on him as a result of opening this account",
    "13-The customer is solely responsible for maintaining the login information for his account ('username - password..)",
    "14-The customer must inform the bank in the event of loss, loss or theft of his device or information related to the digital account, by calling the bank's quadruple number 0119943 or through the numbers mentioned on the bank's website, or by attending in person to one of the bank's branches to report and the bank will be responsible for account suspension within 24 hours from the moment of notifying the bank, except for weekly or official holidays",
    "15-The customer is not entitled to recover any unused balance or withdraw any balance in cash from the account, but his account must be transferred to a regular bank account by referring to the bank if he wishes to do so.",
    "16-The bank is not obligated to implement the customer's instructions on the account when the balance in the account is insufficient at the time of executing the required operation.",
    "17-The customer has the right to cancel his digital account by visiting one of the bank's branches",
    "18-The digital account is frozen by the bunk in the event that the customer does not use the account for the specified period, according to the instructions of the Central Bank of Syria",
    "19-The bank has the right to prevent the customer from using his account in the event of a legal attachment or freezing, or in the event of any local or international sanctions being imposed on the customer's name.",
    "20-The bank has the right to stop the account services upon the occurrence of any network failure or if the bank deems it necessary to stop it, without the need to inform the customer, and the customer in this case is not entitled to ask the bank for any compensation or other amounts",
    "21-The bank is not responsible in the event of any malfunction beyond its control, or any emergency circumstance or force majeure. ",
    "22-All digital account operations are subject to the laws and instructions for combating money laundering and terrorist financing in force in the Syrian Arab Republic",
    // "23-The customer is solely responsible for any illegal use of the digital account in a manner that does not comply with the laws and regulations in force, and he alone bears the risks resulting from that.",
    "23-The use of the electronic channel is limited to the customer who subscribes to the electronic channel and the customer is solely responsible for any unlawful use of the digital account not in accordance with applicable laws and regulations, and bears the risks arising therefrom",
    "24-In the event that the customer wishes to use this account for traditional banking transactions, he must come to the bank to amend the nature of the account and complete the due procedures.",
    "25-The Syrian courts are competent to settle every dispute that may arise between the bank and the customer over this account",
    "26-Sending a text message by the bank to the mobile number authorized by the customer is considered a notification to the customer",
    "27-The customer takes the address indicated by him upon opening this account as a chosen place for notification in connection with this account.",
    "28-The bank has the right to amend these terms and conditions from time to time according to the bank's absolute discretion, and the customer's continued use of the services and the account is his approval of these amendments",
    "Client Acknowledgment: The client acknowledges the following:",
    "1- I declare that I have full legal capacity and mental strength, that I am over eighteen years of age, that I am qualified to open and manage a digital account with the bank on my own, and that I am not one of the people with special cases that require certain procedures (such as the blind or the illiterate).",
    "2- I declare the existence of a valid personal identity document or residence permit",
    "3- I declare that I do not have a previous account with Bank of Jordan- Syria",
    "4- I declare that I accept opening a digital account via the Internet and that I am the true beneficiary of the account ",
    "5- I declare that I accept that Bank of Jordan - Syria requested electronic services that help me use my account and send passwords via sms to the mobile number specified by me when opening the account",
    "6- I declare that the mobile number authorized by me when filling out the electronic form when opening this account is in my possession and used by me, and I exempt the bank from the provisions of banking secrecy in the event that the opposite appears",
    "7- I acknowledge my agreement to receive notifications on the mobile number authorized by me",
    "8- I acknowledge the correctness and accuracy of the data and information that was filled in by me according to the electronic form",
    "9- I declare that I am not included in any of the black lists and attachment lists, and that I am not one of the prohibited persons to deal with",
    "10- I declare that I abide by the anti-money laundering and terrorist financing laws and regulations in effect in the Syrian Arab Republic and all that may come with them to be issued later",
    "11- I declare that the funds deposited or transferred to and from my account are legitimate funds and that I bear the responsibility if the opposite appears",
    "12- I acknowledge the acceptance of incoming transfers and deposits made on the account within the ceiling specified for the account only",
    "13- I acknowledge providing the bank at any time with any information or data it requests regarding the account.",
    "14- I acknowledge that I have read and agreed to the terms and conditions related to the electronic account",
    "*The bank has the right to suspend the digital account and stop providing electronic services without any prior warning, while preserving its right to take all necessary measures in the following cases:",
    "1- In the event that the customer submits incorrect information according to the electronic form filled out by him.",
    "2- In the event that the account is used in a manner that violates the laws and regulations in force ",
  ];
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxwidth={"lg"}
        PaperProps={{ className: "red" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: "primary.main",
            px: "5vw",
            pt: "5vw",
            textAlign: "center",
          }}
        >
          {t("Terms & Conditions")}
        </DialogTitle>
        <DialogContent sx={{ px: "1vw" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "primary.main" }}
          >
            <Typography
              varient="p"
              style={{
                height: "20vh",
                overflowY: "scroll",
                paddingBlock: "10px",
                marginBottom: "10px",
              }}
            >
              {lang === "ar" ? (
                <>
                  <p>
                    <strong>الشروط والأحكام الخاصة بالحساب الالكتروني </strong>
                  </p>
                  <ul>
                    <li>
                      <strong>المصرف</strong>:بنك الاردن - سورية
                    </li>
                    <li>
                      <strong> الحساب الرقمي:</strong> هو حساب مصرفي يتم إنشاؤه
                      من خلال المواقع الالكترونية للمصرف ،و هو خاص حصراً بتنفيذ
                      عمليات الدفع والتحويل الالكتروني التي يحددها اجراءاتها و
                      ضوابطها المصرف
                    </li>
                    <li>
                      <strong>العميل:</strong> هو الشخص الطبيعي الراغب بانشاء
                      حساب الكتروني لدى المصرف.
                    </li>
                    <br />
                    <ol>
                      <li>
                        إن مجرد تسجيل العميل لبياناته على الموقع الالكتروني
                        للمصرف لفتح الحساب، لا يُرتب أي التزامات على المصرف تجاه
                        العميل طالما لم يتم إشعاره من قبل المصرف بفتح الحساب
                      </li>
                      <li>
                        لا يمكن فتح حساب رقمي للأشخاص الذين يملكون حساب مصرفي
                        سابق لدى المصرف
                      </li>
                      <li>
                        إن الحد الأعلى لأي عملية إيداع في الحساب الرقمي هو
                        5.000.000 ل.س (فقط خمسة ملايين ليرة سورية لا غير) ويُمنع
                        تنفيذ أي عملية تفوق هذا الحد(الحد الاعلى لرصيد الحساب
                        5.000.000 ل.س)
                      </li>
                      <li>
                        يلتزم العميل عند استخدامه للقنوات الإلكترونية والاستفادة
                        منها الالتزام بكافة التعليمات وسياسة الخصوصية ضمن أغراض
                        فتح الحساب المصرح بها للبنك، ويحق للبنك تقييد استخدام
                        الحسابات وذلك بحدود حاجة صاحب الحساب دون إشعار مسبق
                        عطفاً على تعميم هيئة مكافحة غسل الأموال وتمويل الارهاب
                        رقم 277/18/ص تاريخ 7/3/2024 منعاً لاستخدام تطبيق
                        الموبايل البنكي لتنفيذ عمليات الدفع الالكتروني للعموم
                        على سبيل المهنة.
                      </li>
                      <li>
                        يمكن تغذية الحساب عن طريق الحوالات المصرفية أو نقداً من
                        خلال فروع المصرف العاملة
                      </li>
                      <li>لا يمكن إصدار بطاقة صراف آلي للحساب الرقمي</li>
                      <li>
                        يًعامل الحساب الرقمي معاملة الحساب الجاري ولا يتقاضى اي
                        فائدة عليه لصالح العميل
                      </li>
                      <li>
                        يجب أن يكون رقم الهاتف الجوال المستخدم لفتح الحساب
                        الرقمي عائد لأحد شركات الخليوي المرخصة في سورية حصراً
                      </li>
                      <li>
                        لا يمكن فتح أكثر من حساب رقمي لنفس العميل ونفس الرقم
                        الوطني
                      </li>
                      <li>
                        يلتزم العميل بمراجعة المصرف لإعلامه بأي تغيير أو تعديل
                        على معلوماته الشخصية المصرح بها
                      </li>
                      <li>
                        يحدد المصرف العمولات المترتبة لقاء خدمات هذا الحساب، كما
                        يحق له تعديل هذه العمولات في أي وقت حيث تكون سارية
                        المفعول بأثر فوري دون الحاجة إلى تبليغ العميل
                      </li>
                      <li>
                        يتحمل العميل وحده الضرائب والنفقات والعمولات التي تقع
                        على عاتقه بنتيجة فتحه هذا الحساب
                      </li>
                      <li>
                        يتحمل العميل وحده مسؤولية المحافظة على معلومات الدخول
                        إلى حسابه ( اسم المستخدم – كلمة المرور .. )
                      </li>
                      <li>
                        يجب على العميل إعلام المصرف في حال فقدان أو ضياع أو سرقة
                        جهازه أو المعلومات المتعلقة بالحساب الرقمي، وذلك عن طريق
                        الاتصال على الرقم الرباعي للمصرف 0119943 او من خلال
                        الارقام المذكورة على موقع المصرف الالكتروني أو الحضور
                        شخصياً الى أحد فروع المصرف للإبلاغ. ويكون المصرف مسؤولا
                        عن ايقاف الحساب خلال 24 ساعة من لحظة تبليغ المصرف ماعدا
                        أيام العطل الأسبوعية أو الرسمية
                      </li>
                      <li>
                        لا يحق للعميل استعادة اي رصيد غير مستخدم أو سحب أي رصيد
                        نقداً من الحساب، أو إنما يجب تحويل حسابه لحساب مصرفي
                        عادي من خلال مراجعته للمصرف في حال رغبته بذلك
                      </li>
                      <li>
                        إن المصرف غير ملزم بتنفيذ تعليمات العميل على الحساب
                        عندما يكون الرصيد الموجود في حساب صاحب الحساب غير كاف
                        وقت تنفيذ العملية المطلوبة
                      </li>
                      <li>
                        يحق للعميل إلغاء حسابه الرقمي من خلال مراجعته لأحد فروع
                        المصرف
                      </li>
                      <li>
                        يتم تجميد الحساب الرقمي من قبل المصرف في حال عدم استخدام
                        العميل للحساب للمدة المحددة حسب تعليمات مصرف سورية
                        المركزي
                      </li>
                      <li>
                        يحق للمصرف منع العميل من استخدام حسابه في حال ورود حجز
                        قانوني أو تجميد أو في حال صدور أي عقوبات محلية أو دولية
                        على اسم العميل
                      </li>
                      <li>
                        يحق للمصرف إيقاف خدمات الحساب عند حدوث أي عطل في الشبكة
                        أو في حال ارتأى المصرف بأن الضرورة تقتضي ايقافها، وذلك
                        دون حاجة لإعلام العميل، ولا يحق للعميل في هذه الحالة
                        مطالبة المصرف بأي تعويض أو مبالغ أخرى
                      </li>
                      <li>
                        إن المصرف غير مسؤول في حال حصول أي عطل خارج عن إرادته أو
                        أي ظرف طارئ أو قوة قاهرة تخضع جميع عمليات الحساب الرقمي
                        لقوانين وتعليمات مكافحة غسل الأموال وتمويل الارهاب
                        السارية في الجمهورية العربية السورية
                      </li>
                      <li>
                        تخضع جميع عمليات الحساب الرقمي لقوانين وتعليمات مكافحة
                        غسل الأموال وتمويل الارهاب السارية في الجمهورية العربية
                        السورية
                      </li>
                      <li>
                        يكون استخدام القناة الإلكترونية مقتصرا على العميل
                        المشترك في القناة الإلكترونية و العميل وحده المسؤول عن
                        أي استخدام غير مشروع للحساب الرقمي بشكل لا يتفق مع
                        القوانين والأنظمة النافذة، ويتحمل وحده المخاطر الناجمة
                        عن ذلك
                      </li>
                      <li>
                        في حال رغبة العميل باستخدام هذا الحساب بالتعاملات
                        المصرفية التقليدية فإنه يتوجب عليه الحضور إلى المصرف
                        لتعديل طبيعة الحساب واستكمال الإجراءات الواجبة أصولاً.
                      </li>
                      <li>
                        إن المحاكم السورية هي المختصة بالنظر في كل نزاع قد ينشب
                        بين المصرف والعميل حول هذا الحساب
                      </li>
                      <li>
                        يعد إرسال المصرف لرسالة نصية على رقم الجوال المصرح به من
                        قبل العميل هو بمثابة تبليغ للعميل
                      </li>
                      <li>
                        كما اتخذ العميل العنوان المبين من قبله لدى فتح هذا
                        الحساب موطناً مختاراً للتبليغ في ما يتعلق بهذا الحساب
                      </li>
                      <li>
                        يحق للمصرف تعديل هذه الأحكام والشروط من وقت لأخر وفقاً
                        لتقدير المصرف المطلق، ويعد استمرار العميل في استخدام
                        الخدمات والحساب موافقة منه على تلك التعديلات
                      </li>
                    </ol>
                    <li>
                      <strong>إقرارات العميل، يقر العميل بما يلي:</strong>
                    </li>
                    <ol>
                      <li>
                        أقر بأنني بكامل أهليتي القانونية وقواي العقلية وبأنني
                        تجاوزت الثامنة عشرة من العمر وبأنني أهل لفتح وإدارة حساب
                        رقمي لدى المصرف بمفردي وبأنني لست من أصحاب الحالات
                        الخاصة التي تتطلب إجراءات معينة ( كالكفيف أو الأمي )
                      </li>
                      <li>
                        أقر بوجود مستند الهوية الشخصية أو الإقامة سارية الصلاحية
                      </li>
                      <li>
                        أقر بأنني لا أملك حساب سابق لدى بنك الاردن * سورية
                      </li>
                      <li>
                        أقر بقبولي فتح حساب رقمي عبر الانترنت وبأنني المستفيد
                        الحقيقي من الحساب
                      </li>
                      <li>
                        أقر بقبولي قيام بنك الاردن – سورية  بطلب الخدمات
                        الالكترونية التي تساعدني في استخدام حسابي وارسال كلمات
                        السر من خلال sms  الى رقم الموبايل المحدد من قبلي عند
                        فتح الحساب
                      </li>
                      <li>
                        أقر بأن رقم الجوال المصرح به من قبلي لدى تعبئتي للنموذج
                        الالكتروني عند فتح هذا الحساب هو بحوزتي ومستخدم من قبلي،
                        وأعفي المصرف من أحكام السرية المصرفية في حال ظهر عكس ذلك
                      </li>
                      <li>
                        أقر بموافقتي على استقبال التبليغات على رقم الجوال المصرح
                        به من قبلي
                      </li>
                      <li>
                        أقر بصحة ودقة البيانات والمعلومات التي تمت تعبئتها من
                        قبلي وفق النموذج الالكتروني
                      </li>
                      <li>
                        أقر بأنني غير مدرج على أي من القوائم السوداء و قوائم
                        الحجز الاحتياطي و بأنني لست من الأشخاص المحظور التعامل
                        معهم
                      </li>
                      <li>
                        أقر بأنني ألتزم بتعليمات وقوانين مكافحة غسل الأموال
                        وتمويل الارهاب السارية في الجمهورية العربية السورية وبكل
                        ما قد يصدر لاحقاً
                      </li>
                      <li>
                        أقر بأن الأموال المودعة أو المحولة من وإلى حسابي هي
                        أموال مشروعة وبأنني أتحمل المسؤولية في حال ظهر عكس ذلك
                      </li>
                      <li>
                        أقر بقبولي للحوالات الواردة والايداعات التي تتم على
                        الحساب ضمن السقف المحدد للحساب فقط
                      </li>
                      <li>
                        أقر بتزويد المصرف في أي وقت بأي معلومات أو بيانات يطلبها
                        خاصة بالحساب
                      </li>
                      <li>
                        أقر بأنني اطلعت على الأحكام والشروط المتعلقة بالحساب
                        الالكتروني ووافقت عليها
                      </li>
                    </ol>
                    <li>
                      يحق للمصرف إيقاف الحساب الرقمي ووقف تقديم الخدمات
                      الالكترونية دون اي انذار مسبق ، مع الاحتفاظ بحقه في اتخاذ
                      كافة الإجراءات اللازمة وذلك في الحالات التالية:
                    </li>
                    <ol>
                      <li>
                        في حال تقديم العميل لمعلومات غير صحيحة وفق النموذج
                        الالكتروني المملوء من قبله
                      </li>
                      <li>
                        في حال استخدام الحساب بشكل يخل بالقوانين والأنظمة
                        النافذة
                      </li>
                      <li>
                        في حال عدم تقيده ومخالفته لأي من قواعد استخدام الحساب
                        وفقاً لأحكام الشروط السارية
                      </li>
                      <li>
                        في حال عدم مراجعة المصرف عند طلبه لذلك لزوم تقديم أو
                        تحديث أي بيانات أو معلومات مطلوبة من قبله
                      </li>
                      <li>في حال علم المصرف بوفاة العميل</li>
                    </ol>
                  </ul>
                </>
              ) : (
                <div>
                  {text.map((item, index) => (
                    <div className={{ marginTop: "5px" }} key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  name={t("أنا موافق على الشروط والأحكام")}
                />
              }
              label={t("أنا موافق على الشروط والأحكام")}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", px: "5vw", pb: "5vw" }}>
          <Button
            color="primary"
            variant="filled"
            disabled={checked ? false : true}
            sx={{
              width: "100%",
              mt: "10px",
              color: "white",
              background: "#081937",
              "&.Mui-disabled": {
                backgroundColor: "#818ea3",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#31466b",
                color: "white",
              },
            }}
            onClick={handleOpenDialog}
          >
            {t("Submit")}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              width: "100%",
              mt: "10px",
              color: "text.primary",
              background: "#F9B700",
              "&.Mui-disabled": {
                backgroundColor: "#bfb9a7",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#f9d160",
              },
            }}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
