import React from "react";
import { Drawer } from "../styled/Drawer";
import { useTranslation } from "react-i18next";
import logo from "../../asset/images/logo.png"
import logoSm from "../../asset/images/logoWide.png"

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { settingsStore } from "store/settingsStore";
import LanguageIcon from '@mui/icons-material/Language';
import { Box } from "@mui/material";

const SideBar = () => {
  const { t, i18n } = useTranslation("navbar");
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);

  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
  };

  return (
    <Drawer
      variant="permanent"
      anchor={isMobile ? 'top' : 'left'}
    >
      <div className="SideNav">
        <picture>
          <source style={{ width: '100%' }} media="(min-width: 600px)" srcSet={logo} />
          <img src={logoSm} alt=" " />
        </picture>
        <Box sx={{ display: 'flex' }}>
          <a style={{ color: '#fff', margin: '5px' }} className="phoneIcon" href="tel:0119943"> <LocalPhoneIcon /></a>
          <a style={{ color: '#fff', margin: '5px' }} className="phoneIcon" href="tel:01122900000"> <HelpOutlineIcon /></a>
          <a style={{ color: '#fff', margin: '5px' }} className="phoneIcon" href="mailTo:info@bankofjordansyria.com"> <MailOutlineIcon /></a>
          <div className=" mobileNav" style={{ margin: '5px' }}>
            <button
              onClick={toggleLang}
            >
              <LanguageIcon />

            </button>
          </div>
        </Box>
        <div className="text">
          <p style={{ color: '#F9B700' }}>
            <div className="mt-md-1">{t('For more contact us on the following numbers')}:</div>
            <a style={{ color: '#F9B700', textDecoration: 'none', my: 1 }} href="tel:0119943">0119943</a> -
            <a style={{ color: '#F9B700', textDecoration: 'none', my: 1 }} href="tel:01122900000">22900000</a>
            <div className="mt-md-1">
              {t('or Contact us on Email')} :
            </div>
            <a style={{ color: '#F9B700', textDecoration: 'none', my: 1 }} href="mailTo:Electronic-services@bankofjordansyria.com">Electronic-services@bankofjordansyria.com</a>
          </p>
        </div>
      </div>
    </Drawer>
  );
};

export default SideBar;
