import { _axios } from "interceptor/http-config";

export const _syrian = {
    index: async () => {
        return _axios
            .get(
                `/api/user/syrian`,
                {
                    headers: {
                        lang: ["en", "ar"].includes(
                            localStorage.getItem("i18nextLng")
                        )
                            ? localStorage.getItem("i18nextLng")
                            : "en"
                    }
                }
            )
            .then((res) => res.data);
    },
    post: (data, setLoading, navigate) =>
        _axios.post("/api/user/syrian", data).then((res) => {
            setLoading(true);
            return res.data
        }),
};